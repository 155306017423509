import { Field } from '@fluentui/react-components'
import { type ReactElement, useContext } from 'react'
import type { TLShapeId } from 'tldraw'
import { track, useEditor } from 'tldraw'
import { isPredictGroupSampleShape } from '../../predict/group-sample/shape'
import { t } from '../../util/intl/t'
import { SampleSetValueContext, SampleValueContext } from '../context/value'
import { SampleModelSelect } from '../model/select'

/**
 * Ask the user to choose the AI model to save the sample under its library.
 * This is a limitation, see module README.md for AI Model section.
 */
function SamplePredictModel(props: {
  id: TLShapeId
}): ReactElement | null {
  const { id } = props

  const editor = useEditor()
  const sampleContext = useContext(SampleValueContext)
  const setSampleContext = useContext(SampleSetValueContext)

  const shape = editor.getShape(id)
  if (shape === undefined)
    throw new Error(`Shape not found ${id}`)
  if (!isPredictGroupSampleShape(shape))
    throw new Error(`Shape ${id} is not predict sample`)

  if (shape.meta.libraryToSave === false)
    return null

  const setValue = (modelId: string) => {
    setSampleContext(prev => ({ ...prev, modelIdToAdd: modelId }))
  }

  return (
    <Field
      label={t('sample.predict.model.label')}
      hint={t('sample.predict.model.hint')}
    >
      <SampleModelSelect
        value={sampleContext.modelIdToAdd}
        setValue={setValue}
      />
    </Field>
  )
}

const tracked = track(SamplePredictModel)

export { tracked as SamplePredictModel }
