import type { TLGeoShape, TLShape } from 'tldraw'
import { isGeoShape } from '../../editor/shape/geo'

export type PredictGroupSampleShapeMeta = {
  type: 'predict-group-sample'
  id: string
  group_template: string | null
  libraryToSave: boolean
}

export type PredictGroupSampleShape =
  TLGeoShape
  & { meta: PredictGroupSampleShapeMeta }

export function isPredictGroupSampleShape(shape: TLShape): shape is PredictGroupSampleShape {
  const test = shape as PredictGroupSampleShape
  return isGeoShape(test) && test.meta.type === 'predict-group-sample'
}

export function toPredictGroupSampleShape(geo: TLGeoShape): PredictGroupSampleShape {
  const area: PredictGroupSampleShape = {
    ...geo,
    opacity: 1,
    props: {
      ...geo.props,
      geo: 'rectangle',
      color: 'blue',
      dash: 'solid',
      fill: 'solid',
      size: 's',
    },
    meta: {
      type: 'predict-group-sample',
      id: crypto.randomUUID(),
      group_template: null,
      libraryToSave: false,
    },
  }

  return area
}
