import { makeStyles, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { useEditorTheme } from '../../editor/util/theme'
import { GROUP_TEMPLATES } from '../../predict/group-sample/value'
import { t } from '../../util/intl/t'
import type { SampleDetail } from '../detail'

const useStyles = makeStyles({
  container: {
    flex: '1 1 0px',
    display: 'flex',
    alignItems: 'center',
    gap: tokens.spacingVerticalS,
  },
  img: {
    aspectRatio: '1/1',
    objectFit: 'contain',
    border: `1px solid ${tokens.colorNeutralStroke1}`,
  },
  text: {
    color: 'var(--sample-option-label-color)',
    fontWeight: tokens.fontWeightSemibold,
  },
})

declare module 'react' {
  // eslint-disable-next-line ts/consistent-type-definitions
  interface CSSProperties {
    '--sample-option-label-color'?: string
  }
}

export function SampleOptionLabel(props: {
  detail: SampleDetail
}): ReactElement {
  const { detail } = props

  const s = useStyles()
  const theme = useEditorTheme()

  const template = GROUP_TEMPLATES
    .find(template => template.value === detail.group) ?? null
  const color = theme[template?.color ?? 'black']

  const image = (
    <img
      className={s.img}
      src={`data:image/png;base64,${detail.image}`}
      width={tokens.spacingHorizontalXXXL}
    />
  )

  const text = (
    <span
      className={s.text}
      style={{
        '--sample-option-label-color': color.solid,
      }}
    >
      {t('sample.option.label.group')}
      {template?.label ?? 'Unknown'}
    </span>
  )

  return (
    <span className={s.container}>
      {image}
      {text}
    </span>
  )
}
