import { useQuery } from '@tanstack/react-query'
import type { ZoneShapePartial } from '../../annot/polygon/zone/shape'
import { fromServerZone } from '../../annot/polygon/zone/shape'
import { server } from '../../util/data/server'
import { PAGE_ID } from '../state/id'

export type PageMetaData = Record<string, any> & {
  zones: ZoneShapePartial[]
} | null

function toMetadata(metadata: string): PageMetaData {
  if (metadata === '')
    return null
  const server = JSON.parse(metadata)

  const meta: Record<string, any> = {}
  let zones: ZoneShapePartial[] = []
  for (const [key, value] of Object.entries(server)) {
    if (key === 'zones' && Array.isArray(value))
      zones = value.map(zone => fromServerZone({ server: zone }))
    else
      meta[key] = value
  }
  return {
    zones,
    ...meta,
  }
}

export function usePageMetadata() {
  return useQuery({
    queryKey: ['metadata', PAGE_ID],
    queryFn: async () => {
      try {
        return await server.getPageMetadataDetail(PAGE_ID)
      }
      catch (e) {
        return { metadata: JSON.stringify({}) }
      }
    },
    select: data => toMetadata(data.metadata),
  })
}
