import { FocusTrapZone } from '@fluentui/react'
import type { PositioningImperativeRef } from '@fluentui/react-components'
import { Button, Dropdown, Input, Option, OptionGroup, Popover, PopoverSurface, makeStyles, tokens, useFocusFinders, useModalAttributes, useUncontrolledFocus } from '@fluentui/react-components'
import { Add16Regular, Square20Filled } from '@fluentui/react-icons'
import { useEffect, useId, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import type { TLDefaultColorThemeColor } from 'tldraw'
import { Polygon2d, Vec, useEditor } from 'tldraw'
import { useEditorTheme } from '../../../editor/util/theme'
import { linePointsToArray } from '../../../predict/polygon-area/util'
import { useSetting } from '../../../setting/setting'
import { Field } from '../../../ui/field'
import { t } from '../../../util/intl/t'
import { getStrict } from '../../../util/web/primitive'
import { ANNOT_SHAPE_COLOR_OPTIONS } from '../../shape/color'
import { useCreateZone, useListZone } from '../server'
import type { ZoneShape } from './shape'

const useStyles = makeStyles({
  action: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: tokens.spacingHorizontalM,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: tokens.spacingHorizontalMNudge,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: tokens.spacingHorizontalM,
  },
})

export type CreateZoneValues = {
  name: string
  color: string
}
export function ZoneDropdownComponent(props:
{
  shape: ZoneShape
}) {
  const { shape } = props

  const s = useStyles()
  const id = useId()

  const editor = useEditor()
  const theme = useEditorTheme()
  const viewZone = useSetting().setting.viewZone

  const listZones = useListZone().data

  const [selectedOptions, setSelectedOptions] = useState<string[]>([shape.meta.zoneId])
  const [create, setCreate] = useState<boolean>(false)

  const attr = useUncontrolledFocus()

  const form = useForm<CreateZoneValues>({
    defaultValues: {
      name: '',
      color: '',
    },
  })
  const { handleSubmit, control } = form

  const points = linePointsToArray(shape).map(Vec.From)
  const center = new Polygon2d({ points, isFilled: true }).center

  const zoneLabel = listZones?.find(zone => zone.id === shape.meta.zoneId)?.name ?? t('annot.zone.group')

  const [value, setValue] = useState(zoneLabel)

  const [colorValue, setColorValue] = useState<string>('')
  const [colorSelectedOptions, setColorSelectedOptions] = useState<string[]>([
    '',
  ])
  const createZone = useCreateZone()
  const onSubmit = handleSubmit((values: CreateZoneValues) => {
    createZone.mutate(values, {
      onSuccess: (data) => {
        setCreate(false)
        if (data.id) {
          setValue(values.name)
          setSelectedOptions([data.id])

          setColorValue('')
          setColorSelectedOptions([''])

          editor.updateShape({
            ...shape,
            meta: { ...shape.meta, zoneId: data.id, zoneName: values.name },
            props: {
              ...shape.props,
              color: values.color,
            },
          })
        }
        form.reset()
      },
    })
  })

  const { triggerAttributes, modalAttributes } = useModalAttributes({
    trapFocus: true,
  })
  const { findFirstFocusable } = useFocusFinders()
  const triggerRef = useRef<HTMLButtonElement>(null)
  const dialogRef = useRef<HTMLDivElement>(null)

  const [open, setOpen] = useState(false)
  const positioningRef = useRef<PositioningImperativeRef>(null)

  useEffect(() => {
    if (create && dialogRef.current)
      findFirstFocusable(dialogRef.current)?.focus()
  }, [open, findFirstFocusable, create])

  useEffect(() => {
    if (triggerRef.current)
      positioningRef.current?.setTarget(triggerRef.current)
  }, [triggerRef, positioningRef])

  useEffect(() => {
    if (shape.meta.zoneId) {
      editor.updateShape({
        ...shape,
        meta: { ...shape.meta, zoneName: zoneLabel },
      })
    }
  }, [shape.meta.zoneId, zoneLabel, editor, shape])

  if (listZones === undefined)
    return null

  return (
    <div
      style={{
        transform: `translate(${center.x}px, ${center.y}px)`,
        position: 'absolute',
        pointerEvents: !viewZone ? 'none' : 'all',
      }}
      // https://tldraw.dev/examples/shapes/tools/editable-shape
      onPointerDown={e => e.stopPropagation()}
    >
      <Button
        appearance="primary"
        style={{
          minWidth: '36px',
          minHeight: '6px',
          maxHeight: '12px',
          maxWidth: '80px',
          fontSize: '5px',
          padding: 0,
        }}
        ref={triggerRef}
        {...triggerAttributes}
        onClick={() => setOpen(true)}
      >
        {value}
      </Button>

      <Popover
        {...modalAttributes}
        onOpenChange={(_, data) => {
          setOpen(data.open)
          triggerRef.current?.focus()
        }}
        open={open}
        positioning={{ positioningRef }}
      >
        <PopoverSurface
          aria-labelledby={id}
          style={{
            minWidth: '240px',
          }}
          ref={dialogRef}
        >
          {create === false
            ? (
              <Field label={t('annot.zone.dropdown-label')}>
                <Dropdown
                  aria-labelledby="group"
                  value={value}
                  selectedOptions={selectedOptions}
                  onOptionSelect={(event, data) => {
                    event.stopPropagation()
                    if (data.optionValue === 'create') {
                      setCreate(true)
                      setValue('')
                      setSelectedOptions([''])
                      return
                    }
                    const value = getStrict(data.optionValue)
                    const color = listZones.find(zone => zone.id === value)?.color ?? 'blue'
                    editor.updateShape({
                      ...shape,
                      meta: { ...shape.meta, zoneId: value, zoneShapeId: shape.id, zoneName: data.optionText },
                      props: {
                        ...shape.props,
                        color,
                      },
                    })

                    setValue(data.optionText ?? '')
                    setSelectedOptions(data.selectedOptions)
                    setOpen(false)
                  }}
                  appearance="filled-darker"
                >
                  <OptionGroup>
                    {listZones.map(option => (
                      <Option
                        key={`${option.id}`}
                        value={option.id}
                        text={option.name}
                      >
                        <Square20Filled style={{ color: (theme[option.color as keyof typeof theme] as TLDefaultColorThemeColor).solid }} />
                        {`${option.name}`}
                      </Option>
                    ),
                    )}
                  </OptionGroup>
                  <OptionGroup>
                    <Option value="create" text="create">
                      <Add16Regular />
                      {t('annot.zone.dropdown-add-new')}
                    </Option>
                  </OptionGroup>
                </Dropdown>
              </Field>
              )
            : (
              <FocusTrapZone
                {...attr}
                isClickableOutsideFocusTrap
                forceFocusInsideTrap={false}
              >
                <form onSubmit={onSubmit} className={s.content}>
                  <div className={s.form}>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      name="name"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Field label={t('annot.zone.name-label')}>
                          <Input
                            value={value}
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder={t('annot.zone.name-placeholder')}
                          />
                        </Field>
                      )}
                    />
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      name="color"
                      render={({ field: { onChange, onBlur } }) => (
                        <Field label={t('annot.zone.color-label')}>
                          <Dropdown
                            onBlur={onBlur}
                            placeholder={t('annot.zone.color-placeholder')}
                            onOptionSelect={(_event, data) => {
                              onChange(data.optionValue)
                              setColorValue(data.optionText ?? '')
                              setColorSelectedOptions(data.selectedOptions)
                            }}
                            value={colorValue}
                            selectedOptions={colorSelectedOptions}
                          >
                            {ANNOT_SHAPE_COLOR_OPTIONS.map(color => (
                              <Option key={color.value} text={color.label} value={color.value}>
                                <Square20Filled style={{ color: (theme[color.value as keyof typeof theme] as TLDefaultColorThemeColor).solid }} />
                                {color.label}
                              </Option>
                            ))}
                          </Dropdown>
                        </Field>
                      )}
                    />
                  </div>
                  <div className={s.action}>
                    <div />
                    <div className={s.action}>
                      <Button
                        onClick={() => {
                          setCreate(false)

                          setColorValue('')
                          setColorSelectedOptions([''])
                        }}
                        size="small"
                      >
                        {t('annot.zone.cancel-button')}
                      </Button>
                      <Button onClick={onSubmit} appearance="primary" size="small">{t('annot.zone.add-button')}</Button>
                    </div>
                  </div>
                </form>
              </FocusTrapZone>
              )}
        </PopoverSurface>
      </Popover>
    </div>
  )
}
