import { type ReactElement, useRef } from 'react'
import { useEditor, useValue } from 'tldraw'
import { SelectGroupSample } from '../../predict/group-sample/select'
import { isPredictGroupSampleShape } from '../../predict/group-sample/shape'
import { usePassThroughWheelEvents } from '../../util/web/wheel'

// Wrapper component that ensures a consistent DOM element for event handling.
// The child component `EditorInfrontOfCanvas` may render null, but we need
// a stable container div to maintain event listeners regardless of child content.
export function EditorInfrontOfCanvasWrapper() {
  const ref = useRef<HTMLDivElement>(null)
  usePassThroughWheelEvents(ref)

  return (
    <div ref={ref}>
      <EditorInfrontOfCanvas />
    </div>
  )
}

export function EditorInfrontOfCanvas(): ReactElement | null {
  const editor = useEditor()

  const selectedShape = editor.getOnlySelectedShape()

  const area = selectedShape && isPredictGroupSampleShape(selectedShape) ? selectedShape : null

  const info = useValue(
    'selection bounds',
    () => {
      const screenBounds = editor.getViewportScreenBounds()
      const rotation = editor.getSelectionRotation()
      const rotatedScreenBounds = editor.getSelectionRotatedScreenBounds()
      if (!rotatedScreenBounds)
        return
      return {
        // we really want the position within the
        // tldraw component's bounds, not the screen itself
        x: rotatedScreenBounds.x - screenBounds.x,
        y: rotatedScreenBounds.y - screenBounds.y,
        width: rotatedScreenBounds.width,
        height: rotatedScreenBounds.height,
        rotation,
      }
    },
    [editor],
  )

  if (!info || area === null)
    return null

  return (
    <div
      key={area.id}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        transformOrigin: 'top left',
        transform: `translate(${info.x}px, ${info.y}px) rotate(${info.rotation}rad)`,
        pointerEvents: 'all',
      }}
    >
      <SelectGroupSample
        x={info.width / 2 - 20}
        y={info.height}
        shape={area}
      />
    </div>
  )
}
