import type { BoxModel, TLShapeId } from 'tldraw'
import { Box } from 'tldraw'
import { isPieceBoxShape } from '../../annot/piece/box/shape'
import { ATTR_EQUIP_VALUES } from '../../attr/field/equip/value'
import { downloadImage } from '../../debug/image'
import { type SampleDetail, getSampleDetailId, isSampleParam, parseSamplesFromParam, transformSamplesToParam } from '../../sample/detail'
import type { FireProtectionAlarmHead, FireProtectionSprinklerHead } from '../../util/data/server'
import { server } from '../../util/data/server'
import { t } from '../../util/intl/t'
import { BASE64_PNG, base64ToBlob, blobToBase64 } from '../../util/web/blob'
import type { PredictFetchCrop } from '../fetch/crop'
import type { PredictFetch, PredictFetchInput } from '../fetch/type'
import { isPredictGroupSampleShape } from '../group-sample/shape'
import { parsePredictBoxes } from '../parse/box'
import type { PredictModelBase } from './type'

type FirePredictionHead = FireProtectionSprinklerHead | FireProtectionAlarmHead

export function getPredictFireHeads(input: PredictFetchInput): FirePredictionHead[] {
  return input.shapes
    .filter(isPieceBoxShape)
    .map((shape): FirePredictionHead => {
      const global = new Box(shape.x, shape.y, shape.props.w, shape.props.h)
      const local = input.area.localise.box(global)
      const { height, width, x, y } = local

      const attr = input.attrs[shape.meta.group]
      if (attr === undefined)
        throw new Error(`attr is missing for ${shape.meta.group}`)

      return {
        box: { height, width, x, y },
        equipment_class: attr.equip,
      }
    })
}

export function isValidCropSamples(samples: PredictFetchCrop[], areas: PredictFetchCrop[]): boolean {
  const maxCropSample = samples.reduce((max, sample) => {
    const { w, h } = sample.source
    return Math.max(max, w, h)
  }, 0)

  const minCropArea = areas.reduce((min, area) => {
    const { w, h } = area.source
    return Math.min(min, w, h)
  }, Number.POSITIVE_INFINITY)

  return maxCropSample <= minCropArea
}

export async function crop(props: {
  img: HTMLImageElement
  box: BoxModel
}): Promise<Blob> {
  return new Promise((resolve) => {
    const { img, box } = props

    // Prepare canvas
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (ctx === null)
      throw new Error('Failed to get context')

    // Draw image
    const { x, y, w, h } = box;
    [canvas.width, canvas.height] = [w, h]
    ctx.drawImage(img, x, y, w, h, 0, 0, w, h)

    // Export
    canvas.toBlob((blob) => {
      if (blob === null)
        throw new Error('Failed to get blob')
      resolve(blob)
    }, 'image/png')
  })
}

type BlobWithId = {
  id: TLShapeId
  blob: Blob
}

async function getSampleDetail(params: {
  input: PredictFetchInput
  sample: PredictFetchCrop
  blobs: BlobWithId[]
}): Promise<SampleDetail | null> {
  const { input, sample, blobs } = params

  const shape = input.editor.getShape(sample.shapeId)
  if (shape === undefined)
    throw new Error(`shape not found ${sample.shapeId}`)
  if (isPredictGroupSampleShape(shape) === false)
    throw new Error(`shape is not group sample ${sample.shapeId}`)

  const { group_template: group, libraryToSave } = shape.meta
  if (libraryToSave === false)
    return null
  if (group === null)
    throw new Error(`group_template not set for ${sample.shapeId}`)

  const blob = blobs.find(b => b.id === sample.shapeId)
  if (blob === undefined)
    throw new Error(`blob not found ${sample.shapeId}`)

  const base64 = await blobToBase64(blob.blob)
  const image = base64.replace(BASE64_PNG, '')

  return { group, image }
}

export async function savePredictSamplesMaybe(params: {
  input: PredictFetchInput
  blobs: BlobWithId[]
}): Promise<void> {
  const { input, blobs } = params

  const samples = await Promise.all(input.samples.map(async (sample) => {
    return await getSampleDetail({ input, sample, blobs })
  }))

  const filtered = samples.filter(sample => sample !== null)
  if (filtered.length === 0)
    return

  // Create
  if (input.aiParamsDetail === null) {
    await server.createConstructionAiModelParams(input.construction, {
      aiModelID: Number.parseInt(input.sampleContext.modelIdToAdd, 10),
      params: transformSamplesToParam(filtered),
    })
    return
  }

  // Update
  const existing = parseSamplesFromParam(input.aiParamsDetail)
  const added: SampleDetail[] = [...existing, ...filtered]
  await server.updateConstructionAiModelParams(input.aiParamsDetail.id, {
    params: transformSamplesToParam(added),
  })
}

type PredictSamplesMaybe = {
  max: number
  groupIDs: string[]
  images: Blob[]
}

async function getSampleMax(base64: string): Promise<number> {
  const image = base64.replace(BASE64_PNG, '')
  const img = new Image()
  img.src = `data:image/png;base64,${image}`
  await new Promise((resolve) => {
    img.onload = resolve
  })
  return Math.max(img.width, img.height)
}

export async function getPredictSamplesMaybe(params: {
  input: PredictFetchInput
}): Promise<PredictSamplesMaybe> {
  const { input } = params

  const base: PredictSamplesMaybe = {
    max: 0,
    groupIDs: [],
    images: [],
  }

  if (input.aiParamsDetail === null)
    return base

  if (!isSampleParam(input.aiParamsDetail.params))
    return base

  const all = parseSamplesFromParam(input.aiParamsDetail)
  const selected = input.sampleContext.selected
  const toAddRaw = all.filter((sample) => {
    const id = getSampleDetailId(sample)
    return selected.has(id)
  })

  type SampleAdd = {
    id: string
    blob: Blob
    max: number
  }

  const promises: Promise<SampleAdd>[] = toAddRaw.map(async (sample) => {
    const max = await getSampleMax(sample.image)
    const base64 = `${BASE64_PNG}${sample.image}`
    const blob = await base64ToBlob(base64)
    return { id: sample.group, blob, max }
  })

  const toAdd: SampleAdd[] = await Promise.all(promises)

  toAdd.forEach((add) => {
    base.max = Math.max(base.max, add.max)
    base.images.push(add.blob)
    base.groupIDs.push(add.id)
  })

  return base
}

const fetch: PredictFetch = async (input) => {
  const sampleLibrary = await getPredictSamplesMaybe({ input })

  const normalized = await server.aiPredictNormalize({
    max: input.samples.reduce((max, sample) => {
      const { w, h } = sample.source
      return Math.max(max, w, h)
    }, sampleLibrary.max),
    pageID: input.page,
    scale: input.area.source.scale,
  })

  const img = new Image()
  img.src = `data:image/png;base64,${normalized.payload}`

  await new Promise((resolve) => {
    img.onload = resolve
  })

  const blobsPromises: Promise<BlobWithId>[] = input.samples.map(async (sample) => {
    const blob = await crop({ img, box: sample.source })
    return { id: sample.shapeId, blob }
  })
  const blobs: BlobWithId[] = await Promise.all(blobsPromises)

  await savePredictSamplesMaybe({ input, blobs })

  const cropArea = {
    scale: input.area.source.scale,
    x: input.area.source.x / input.area.source.scale,
    y: input.area.source.y / input.area.source.scale,
    w: input.area.source.w / input.area.source.scale,
    h: input.area.source.h / input.area.source.scale,
  }

  const templateImages = [
    ...sampleLibrary.images,
    ...blobs.map(blob => blob.blob),
  ]
  const templateGroupIDs = [
    ...sampleLibrary.groupIDs,
    ...input.samples.map(s => s.group!),
  ]

  const image = await crop({ img, box: input.area.source })

  if (window.__debug__.download_images) {
    templateImages.forEach(sample => downloadImage(sample))
    downloadImage(image)
  }
  const heads = getPredictFireHeads(input)

  const raw = await server.predictFireProtectionSprinklerHeadsByAi({
    image,
    pageID: input.page,
    sessionID: input.session,
    templateGroupIDs,
    templateImages,
    cropping: JSON.stringify(cropArea),
    detectedSprinklerHeads: JSON.stringify(heads),
  })

  const boxes = parsePredictBoxes({
    // @TODO: Support generic box, like in pipeline?
    boxes: raw.boxes,
    transform: input.area.globalise,
    fallbackEquip: ATTR_EQUIP_VALUES.SPRINKLER_HEAD,
    aiPredictionRemaining: raw.aiPredictionRemaining,
    polygonArea: input.polygonArea ?? undefined,
  })

  return boxes
}

export const PredictModelFireHead = {
  value: 'fire-head',
  label: t('predict.model.fire-head'),
  system: 'fire',
  sample: true,
  fetch,
  cleanUp: () => { },
  segment: null,
  additionalShape: false,
  aiParams: false,
} as const satisfies PredictModelBase
