import { Field } from '@fluentui/react-components'
import { type ReactElement, useContext } from 'react'
import { t } from '../../util/intl/t'
import { SampleSetValueContext, SampleValueContext } from '../context/value'
import { SampleModelSelect } from '../model/select'

/**
 * Select a model to filter construction samples
 */
export function SampleBrowseModel(): ReactElement {
  const sampleContext = useContext(SampleValueContext)
  const setSampleContext = useContext(SampleSetValueContext)

  const setValue = (modelIdToUse: string) => {
    setSampleContext(prev => ({ ...prev, modelIdToUse }))
  }

  return (
    <Field
      label={t('sample.browse.model.label')}
      hint={t('sample.browse.model.hint')}
    >
      <SampleModelSelect
        value={sampleContext.modelIdToUse}
        setValue={setValue}
      />
    </Field>
  )
}
