import type { AIModelParams, FireProtectionPipeDiameterParams } from '../../../generated/server'
import type { AIParamsMeta } from '../area/shape'

export type AIParamsFormFormula = {
  id: string
  from: string
  to: string
  diameter: string
}

export type AIParamsFormRule = {
  id: string
  equipmentClass: string
  formulas: AIParamsFormFormula[]
}

export type AIParams = {
  hydrantRule: {
    equipmentClass: string
    exchangeValue: string
  }
  rules: AIParamsFormRule[]
}

export function parseAIModelParamsServer(
  params: FireProtectionPipeDiameterParams,
): AIParams {
  const hydrantRule = {
    equipmentClass: params.hydrant_rule.equipment_class.toString(),
    exchangeValue: params.hydrant_rule.exchange_value.toString(),
  }
  const rules = params.rules.map((rule) => {
    return {
      id: crypto.randomUUID(),
      equipmentClass: rule.equipment_class.toString(),
      formulas: rule.formulas.map((formula) => {
        return {
          id: crypto.randomUUID(),
          from: formula.num_head_from.toString(),
          to: formula.num_head_to.toString(),
          diameter: formula.diameter.toString(),
        }
      }),
    }
  })
  return { hydrantRule, rules }
}

export function toAIModelParamsServer(
  data: AIParamsMeta,
): AIModelParams | undefined {
  if (data === null)
    return undefined

  const hydrant_rule = {
    equipment_class: Number.parseInt(data.hydrantRule.equipmentClass),
    exchange_value: Number.parseInt(data.hydrantRule.exchangeValue),
  }
  const rules = data.rules.map((rule) => {
    return {
      equipment_class: Number.parseInt(rule.equipmentClass),
      formulas: rule.formulas.map((formula) => {
        return {
          num_head_from: Number.parseInt(formula.from),
          num_head_to: Number.parseInt(formula.to),
          diameter: Number.parseInt(formula.diameter),
        }
      }),
    }
  })
  return { rules, hydrant_rule }
}
