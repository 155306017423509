export async function blobToBase64(blob: Blob): Promise<string> {
  const promise = new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const { result } = reader
      if (typeof result !== 'string')
        reject(new Error('Failed to read blob'))
      else
        resolve(result)
    }
    reader.onerror = reject

    reader.readAsDataURL(blob)
  })

  return promise
}

export async function base64ToBlob(base64: string): Promise<Blob> {
  const response = await fetch(base64)
  return response.blob()
}

export const BASE64_PNG = 'data:image/png;base64,'
