import { useEffect, useState } from 'react'
import { type Editor, Vec } from 'tldraw'
import type { Zone } from '../../../generated/server'
import { ApiError } from '../../../generated/server'
import { useListZone } from '../../annot/polygon/server'
import { isZoneShape } from '../../annot/polygon/zone/shape'
import { syncPageMetadata } from '../../page/save-metadata'
import { usePage } from '../../page/state/context'
import { PAGE_ID } from '../../page/state/id'
import { linePointsToArray } from '../../predict/polygon-area/util'
import { useSetting } from '../../setting/setting'
import { useToast } from '../../ui/toast'

export function useEditorSyncZones(props: { editor: Editor }): void {
  const { editor } = props
  const { setting } = useSetting()
  const metadata = usePage().metadata
  const initialShapes = metadata?.zones

  const [ready, setReady] = useState(false)
  const { toast } = useToast()

  const { data: listZones } = useListZone()
  const currentZones = editor.getCurrentPageShapes().filter(isZoneShape).filter(shape => shape.meta.zoneId !== '')

  // // @TODO: Use "store" so we can "seed" the shapes in before rendering
  // // - https://canary.tldraw.dev/docs/persistence#The-store-prop
  useEffect(() => {
    if (ready === true || initialShapes === undefined || listZones === undefined)
      return

    for (const shape of initialShapes) {
      const zone = (listZones ?? []).find(zone => zone.id === shape.meta.zoneId)
      if (zone) {
        const update = {
          ...shape,
          meta: {
            ...shape.meta,
            zoneId: zone.id,
          },
          props: {
            ...shape.props,
            color: zone.color ?? 'blue',
          },
          isLocked: !setting.viewZone,
          opacity: setting.viewZone ? 1 : 0,
        }
        editor.createShapes([update])
      }
    }
    editor.clearHistory()
    setReady(true)
  }, [editor, initialShapes, ready, listZones, setting.viewZone])

  useEffect(() => {
    if (ready === false)
      return

    const formatted: Zone[] = currentZones.map((zone) => {
      const points = linePointsToArray(zone).map(Vec.From)
      return ({
        id: zone.meta.zoneId as string,
        polygon: points.map(point => ({ x: point.x + zone.x, y: point.y + zone.y })),
      })
    })

    const id = window.setTimeout(async () => {
      const save = await syncPageMetadata(PAGE_ID, formatted, metadata)
      if (save.status === 'failed') {
        if (save.error instanceof ApiError) {
          toast(save.error.message)
          throw new Error(save.error.message, { cause: save.error.body })
        }
        else {
          toast(save.error)
          throw new Error(save.error)
        }
      }
    }, 2000)

    return () => {
      window.clearTimeout(id)
    }
  }, [currentZones, toast, ready, metadata])
}
