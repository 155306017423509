import type { ReactElement, ReactNode } from 'react'
import { useState } from 'react'
import type { SampleContextValue } from './value'
import { SampleSetValueContext, SampleValueContext } from './value'

export function SampleContextProvider(props: {
  children: ReactNode
}): ReactElement {
  const [value, setValue] = useState<SampleContextValue>({
    selected: new Set(),
    modelIdToAdd: '',
    modelIdToUse: '',
  })

  return (
    <SampleValueContext.Provider value={value}>
      <SampleSetValueContext.Provider value={setValue}>
        {props.children}
      </SampleSetValueContext.Provider>
    </SampleValueContext.Provider>
  )
}
