import { useQuery } from '@tanstack/react-query'
import { useContext } from 'react'
import { usePage } from '../page/state/context'
import { SampleValueContext } from './context/value'
import { sampleQueryList } from './query'

export function useSampleQueryDetail() {
  const { modelIdToUse } = useContext(SampleValueContext)
  const { constructionID } = usePage().page

  return useQuery({
    ...sampleQueryList(constructionID),
    select: (data) => {
      const found = data.find((detail) => {
        return detail.aiModelID.toString() === modelIdToUse
      })
      return found ?? null
    },
  })
}
