import { Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components'
import { MoreVerticalFilled } from '@fluentui/react-icons'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import type { ReactElement } from 'react'
import { usePage } from '../../page/state/context'
import { server } from '../../util/data/server'
import { t } from '../../util/intl/t'
import { type SampleDetail, getSampleDetailId, parseSamplesFromParam, transformSamplesToParam } from '../detail'
import { sampleQueryList } from '../query'
import { useSampleQueryDetail } from '../query-detail'

export function SampleOptionRemove(props: {
  sample: SampleDetail
}): ReactElement {
  const { sample } = props

  const { constructionID } = usePage().page
  const queryClient = useQueryClient()
  const sampleID = getSampleDetailId(sample)
  const listQuery = sampleQueryList(constructionID)
  const paramsQuery = useSampleQueryDetail()

  const mutation = useMutation({
    mutationFn: async () => {
      const params = paramsQuery.data
      if (!params)
        throw new Error('Params detail is not ready to update')
      const prev = parseSamplesFromParam(params)
      const next = prev.filter(s => getSampleDetailId(s) !== sampleID)
      await server.updateConstructionAiModelParams(params.id, {
        params: transformSamplesToParam(next),
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(listQuery)
    },
  })

  const onRemove = () => {
    mutation.mutate()
  }

  const popover = (
    <MenuPopover>
      <MenuList>
        <MenuItem onClick={onRemove}>
          {t('sample.option.remove.label')}
        </MenuItem>
      </MenuList>
    </MenuPopover>
  )

  const trigger = (
    <MenuTrigger disableButtonEnhancement>
      <MenuButton
        appearance="subtle"
        menuIcon={<MoreVerticalFilled fontSize={16} />}
        size="large"
      />
    </MenuTrigger>
  )

  return (
    <Menu>
      {trigger}
      {popover}
    </Menu>
  )
}
