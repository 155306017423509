import type { defaultColorNames } from 'tldraw'

type Color = typeof defaultColorNames[number]

type Template = {
  label: string
  value: string
  color: Color
}

/**
 * In back-end, template's "value" is natively a dynamic string.
 * That is, in each prediction, the user can input any text as "value".
 *
 * In front-end, however, we only support the most common use case so far,
 * which is a number from 1 to 20.
 * In other words, "value" being a number is a convenience, not requirement.
 *
 * Previously, we used an auto generated id for each template.
 * (`crypto.randomUUID` to be exact.)
 * This id is persisted for one session (which could include multiple predictions).
 *
 * Later, we have a concept of "Sample Library", which is based on these templates.
 * Added to the library, samples are then stored persistently, construction-wide.
 * Therefore, we should not use the session-specific generated id any more.
 *
 * This utility is to get a stable "value" for a template.
 */
function getSystemValue(label: string) {
  return `SYSTEM_${label}`
}

export const GROUP_TEMPLATES: Template[] = ([
  { label: '1', color: 'blue' },
  { label: '2', color: 'green' },
  { label: '3', color: 'orange' },
  { label: '4', color: 'violet' },
  { label: '5', color: 'yellow' },
  { label: '6', color: 'light-blue' },
  { label: '7', color: 'light-green' },
  { label: '8', color: 'light-violet' },
  { label: '9', color: 'light-red' },
  { label: '10', color: 'blue' },
  { label: '11', color: 'green' },
  { label: '12', color: 'orange' },
  { label: '13', color: 'violet' },
  { label: '14', color: 'yellow' },
  { label: '15', color: 'light-blue' },
  { label: '16', color: 'light-green' },
  { label: '17', color: 'light-violet' },
  { label: '18', color: 'light-red' },
  { label: '19', color: 'blue' },
  { label: '20', color: 'green' },
] satisfies Omit<Template, 'value'>[])
  .map((template): Template => ({
    ...template,
    value: getSystemValue(template.label),
  }))
