import type { Dispatch, SetStateAction } from 'react'
import { createContext } from 'react'

/**
 * Heads up! We are using the same context for 2 _independent_ use cases of Sample
 */
type Value = {
  /**
   * To select samples for next prediction.
   * See README.md's section on Sample ID
   */
  selected: Set<string>
  /**
   * To select samples for next prediction
   * See README.md's section on AI Model ID
   */
  modelIdToUse: string
  /**
   * To add samples to the construction library
   * See README.md's section on AI Model ID
   */
  modelIdToAdd: string
}

export type { Value as SampleContextValue }

type SetValue = Dispatch<SetStateAction<Value>>

export const SampleValueContext = createContext<Value>({
  selected: new Set(),
  modelIdToUse: '',
  modelIdToAdd: '',
})

export const SampleSetValueContext = createContext<SetValue>(() => {
  throw new Error('SampleSetValueContext is not initialized')
})
