import type { ReactElement } from 'react'
import type { HandleSnapGeometry, TLDefaultFillStyle, TLHandle, TLOnHandleDragHandler, TLOnResizeHandler } from 'tldraw'
import { Polygon2d, SVGContainer, ShapeUtil, Vec, WeakCache, getDefaultColorTheme, getIndexBetween, getIndices, sortByIndex, useDefaultColorTheme, useEditor } from 'tldraw'
import type { PolygonShape } from '../../annot/polygon/shape'
import { ZoneShapeComponent } from '../../annot/polygon/zone/component'
import { isZoneShape } from '../../annot/polygon/zone/shape'
import { PolygonComponent } from '../../predict/polygon-area/component'
import type { PredictPolygonAreaShape } from '../../predict/polygon-area/shape'
import { areaShapeProps, isPredictPolygonAreaShape } from '../../predict/polygon-area/shape'
import ShapeFill from '../../predict/polygon-area/shape-fill'
import { STROKE_SIZES, getGeometryForLineShape, linePointsToArray, mapObjectMapValues } from '../../predict/polygon-area/util'

const handlesCache = new WeakCache<PolygonShape['props'], TLHandle[]>()

/** @public */
export class PolygonShapeUtil extends ShapeUtil<PolygonShape> {
  static override type = 'area' as const
  static override props = areaShapeProps
  // static override migrations = lineShapeMigrations

  override hideResizeHandles = () => true
  override hideRotateHandle = () => true
  override hideSelectionBoundsFg = () => true
  override hideSelectionBoundsBg = () => true

  override getDefaultProps(): PredictPolygonAreaShape['props'] {
    const [start, end] = getIndices(2)
    return {
      color: 'black',
      size: 'm',
      points: {
        [start]: { id: start, index: start, x: 0, y: 0 },
        [end]: { id: end, index: end, x: 0, y: 0 },
      },
    }
  }

  getGeometry(shape: PredictPolygonAreaShape) {
    // todo: should we have min size?
    return getGeometryForLineShape(shape)
  }

  override getHandles(shape: PolygonShape) {
    return handlesCache.get(shape.props, () => {
      const spline = getGeometryForLineShape(shape)
      const points = linePointsToArray(shape)
      const results: TLHandle[] = points.map(point => ({
        ...point,
        id: point.index,
        type: 'vertex',
        canSnap: true,
      }))

      for (let i = 0; i < points.length - 1; i++) {
        const index = getIndexBetween(points[i].index, points[i + 1].index)
        const segment = spline.segments[i]
        const point = segment.midPoint()
        results.push({
          id: index,
          type: 'create',
          index,
          x: point.x,
          y: point.y,
          canSnap: true,
        })
      }

      return results.sort(sortByIndex)
    })
  }

  //   Events
  override onResize: TLOnResizeHandler<PolygonShape> = (shape, info) => {
    const { scaleX, scaleY } = info

    return {
      props: {
        points: mapObjectMapValues(shape.props.points, (_, { id, index, x, y }) => ({
          id,
          index,
          x: x * scaleX,
          y: y * scaleY,
        })),
      },
    }
  }

  override onHandleDrag: TLOnHandleDragHandler<PolygonShape> = (shape, { handle }) => {
    return {
      ...shape,
      props: {
        ...shape.props,
        points: {
          ...shape.props.points,
          [handle.id]: { id: handle.id, index: handle.index, x: handle.x, y: handle.y },
        },
      },
    }
  }

  component(shape: PolygonShape) {
    if (isPredictPolygonAreaShape(shape)) {
      return (
        <PolygonComponent shape={shape}>
          <SVGContainer id={shape.id}>
            <LineShapeSvg shape={shape} fill="semi" />
          </SVGContainer>
        </PolygonComponent>
      )
    }
    if (isZoneShape(shape)) {
      return (
        <ZoneShapeComponent shape={shape}>
          <SVGContainer id={shape.id}>
            <LineShapeSvg shape={shape} fill="semi" />
          </SVGContainer>
        </ZoneShapeComponent>
      )
    }
  }

  indicator(shape: PolygonShape) {
    const spline = getGeometryForLineShape(shape)

    const outline = spline.points
    const path = `M${outline[0]}L${outline.slice(1)}`

    return <path d={path} />
  }

  override toSvg(shape: PolygonShape) {
    if (isZoneShape(shape)) {
      const label = shape.meta.zoneName ?? 'Unknown'

      const points = linePointsToArray(shape).map(Vec.From)
      const center = new Polygon2d({ points, isFilled: true }).center

      const colors = getDefaultColorTheme({ isDarkMode: false })
      return (
        <LineShapeSvg shape={shape} fill="none">
          <g>
            <rect
              fill={colors.blue.solid}
              width="32px"
              height="10px"
              ry="4px"
              rx="4px"
              style={{
                transform: `translate(${center.x}px, ${center.y}px)`,
                padding: '2px',
                opacity: 1,
              }}
            />
            {/* This is much better than trying to re-produce the canvas styling. */}
            <text
              fill="white"
              fontFamily="Arial"
              fontSize="4px"
              strokeWidth="1px"
              style={{
                transform: `translate(${center.x + 2}px, ${center.y + 6}px)`,
              }}
            >
              {label}
            </text>
          </g>
        </LineShapeSvg>
      )
    }

    return <LineShapeSvg shape={shape} fill="none" />
  }

  override getHandleSnapGeometry(shape: PolygonShape): HandleSnapGeometry {
    const points = linePointsToArray(shape)
    return {
      points,
      getSelfSnapPoints: (handle) => {
        const index = this.getHandles(shape)
          .filter(h => h.type === 'vertex')
          .findIndex(h => h.id === handle.id)!

        // We want to skip the current and adjacent handles
        return points.filter((_, i) => Math.abs(i - index) > 1).map(Vec.From)
      },
    }
  }

  calc(shape: PolygonShape) {
    const points = linePointsToArray(shape).map(Vec.From)
    const a = new Polygon2d({ points, isFilled: true })
    return Math.abs(a.getArea())
  }
}

export function LineShapeSvg({ shape, className, fill, children }: {
  shape: PolygonShape
  className?: string
  fill: TLDefaultFillStyle
  children?: ReactElement
}) {
  const theme = useDefaultColorTheme()
  const editor = useEditor()
  const spline = getGeometryForLineShape(shape)
  const strokeWidth = STROKE_SIZES[shape.props.size] / editor.getZoomLevel()
  const { color } = shape.props
  // Line style lines
  const outline = spline.points
  const pathData = `M${outline[0]}L${outline.slice(1)}`

  return (
    <>
      <ShapeFill d={pathData} fill={fill} color={color} theme={theme} className={className} />
      <path d={pathData} stroke={theme[color].solid} strokeWidth={strokeWidth} fill="none" />
      {children
        ? (
          <>
            <g>
              <path fill={theme[color].solid} d={pathData} opacity={0.3} strokeWidth={0} />
            </g>
            {children}
          </>
          )
        : null}
    </>
  )
}
