import { Select, makeStyles, tokens } from '@fluentui/react-components'
import type { ChangeEvent, ReactElement } from 'react'
import { useId } from 'react'
import { useEditor } from 'tldraw'
import { SamplePredictLibrary } from '../../sample/predict/library'
import { t } from '../../util/intl/t'
import type { PredictGroupSampleShape } from './shape'
import { GROUP_TEMPLATES } from './value'

const useStyles = makeStyles({
  container: {
    padding: tokens.spacingHorizontalM,
    backgroundColor: tokens.colorNeutralBackground1,
    border: `1px solid ${tokens.colorNeutralForeground1}`,
    borderRadius: tokens.borderRadiusMedium,
    display: 'flex',
    flexDirection: 'column',
  },
  select: {
    // Sample Predict Library could be quite big
    maxWidth: '100px',
  },
})

export function SelectGroupSample(props: {
  x: number
  y: number
  shape: PredictGroupSampleShape
}): ReactElement | undefined {
  const editor = useEditor()
  const { x, y, shape } = props

  const selectId = useId()
  const s = useStyles()
  return (
    <div
      className={s.container}
      style={{
        transform: `translate(${x}px, ${y + 4}px)`,
      }}
    >
      <label
        htmlFor={selectId}
        style={{ fontWeight: 'bold' }}
      >
        {t('predict.group-sample.group')}
      </label>
      <Select
        className={s.select}
        id={crypto.randomUUID()}
        defaultValue={shape.meta.group_template ?? ''}
        onChange={(event: ChangeEvent<HTMLSelectElement>, data) => {
          event.stopPropagation()
          if (!data.value)
            return
          const group_template = data.value as string

          const next = { ...shape, meta: { ...shape.meta, group_template } }
          editor.updateShape(next)
          editor.mark()
        }}
        size="small"
      >
        <option value="" disabled>-</option>
        {GROUP_TEMPLATES.map(template => (
          <option
            key={template.value}
            value={template.value}
          >
            {template.label}
          </option>
        ),
        )}
      </Select>
      <SamplePredictLibrary shapeId={shape.id} />
    </div>
  )
}
