import { makeStyles, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import type { SampleDetail } from '../detail'
import { SampleOptionCheck } from './check'
import { SampleOptionRemove } from './remove'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'stretch',
    gap: tokens.spacingHorizontalXXS,
  },
})

export function SampleOptionBox(props: {
  detail: SampleDetail
}): ReactElement {
  const { detail } = props

  const s = useStyles()

  return (
    <div className={s.container}>
      <SampleOptionCheck detail={detail} />
      <SampleOptionRemove sample={detail} />
    </div>
  )
}
