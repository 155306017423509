import { Field, makeStyles, tokens } from '@fluentui/react-components'
import { useQuery } from '@tanstack/react-query'
import { type ReactElement, useContext } from 'react'
import { usePage } from '../../page/state/context'
import { t } from '../../util/intl/t'
import { SampleValueContext } from '../context/value'
import { type SampleDetail, getSampleDetailId, isSampleParam, parseSamplesFromParam } from '../detail'
import { SampleOptionBox } from '../option/box'
import { sampleQueryList } from '../query'
import { SampleBrowseModel } from './model'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: tokens.spacingVerticalXL,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: tokens.spacingVerticalM,
  },
})

export function SampleBrowsePanel(): ReactElement {
  const { constructionID } = usePage().page

  const styles = useStyles()

  const { modelIdToUse } = useContext(SampleValueContext)

  const query = useQuery({
    ...sampleQueryList(constructionID),
    select: (data): SampleDetail[] => {
      const byModel = data
        .filter(detail => isSampleParam(detail.params))
        .filter(detail => detail.aiModelID.toString() === modelIdToUse)
        .at(0)
      if (!byModel)
        return []
      return parseSamplesFromParam(byModel)
    },
  })

  const list = (
    <div className={styles.list}>
      <Field
        label={t('sample.browse.panel.title')}
        hint={t('sample.browse.panel.hint')}
      >
      </Field>
      {/* The actual list body stays outside of the Field,
      so that the hint is always on top */}
      {query.data?.length === 0 && (
        <div>
          {t('sample.browse.panel.empty')}
        </div>
      )}
      {query.data?.map(detail => (
        <SampleOptionBox
          key={getSampleDetailId(detail)}
          detail={detail}
        />
      ))}
    </div>
  )

  return (
    <div className={styles.container}>
      <SampleBrowseModel />
      {modelIdToUse !== '' && list}
    </div>
  )
}
