import type { CheckboxProps } from '@fluentui/react-components'
import { Checkbox, Field } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import type { TLShapeId } from 'tldraw'
import { track, useEditor } from 'tldraw'
import type { PredictGroupSampleShape } from '../../predict/group-sample/shape'
import { isPredictGroupSampleShape } from '../../predict/group-sample/shape'
import { t } from '../../util/intl/t'

/**
 * Checkbox to mark the current sample shape as to be saved to the library.
 * At the moment this can only be done during the prediction request.
 */
function SamplePredictSave(props: {
  id: TLShapeId
}): ReactElement {
  const { id } = props

  const editor = useEditor()

  const shape = editor.getShape(id)
  if (shape === undefined)
    throw new Error(`Shape not found ${id}`)
  if (!isPredictGroupSampleShape(shape))
    throw new Error(`Shape ${id} is not predict sample`)

  const onChange: CheckboxProps['onChange'] = (_event, data) => {
    const libraryToSave = data.checked
    if (libraryToSave === 'mixed')
      throw new Error('Mixed checkbox is not allowed')

    editor.updateShape<PredictGroupSampleShape>({
      id,
      type: 'geo',
      meta: { libraryToSave },
    })
  }

  const checkbox = (
    <Checkbox
      checked={shape.meta.libraryToSave}
      onChange={onChange}
      label={t('sample.predict.save.label')}
    />
  )

  // To avoid cognitive overload, we don't show the hint until the user
  // has checked the checkbox.
  const hint = shape.meta.libraryToSave
    ? t('sample.predict.save.hint')
    : undefined

  return (
    <Field hint={hint}>
      {checkbox}
    </Field>
  )
}

const tracked = track(SamplePredictSave)

export { tracked as SamplePredictSave }
