import { createContext, useContext } from 'react'
import type { PageDetail } from '../../util/data/server'
import type { SetState } from '../../util/react/state'
import type { PageAnnots } from '../provider/annot'
import type { UsePageResult } from '../provider/detail'
import type { PageMetaData } from '../provider/metadata'
import type { PagePdf } from '../provider/pdf'

export type PageLimit = Pick<PageDetail, 'aiPredictionRemaining'>

type Context = {
  page: PageDetail
  updatePage: UsePageResult['mutation']
  pdf: PagePdf
  annots: PageAnnots
  limit: PageLimit
  setLimit: SetState<PageLimit | null>
  metadata: PageMetaData | null
}

export const pageContext = createContext<Context | null>(null)

export function usePage() {
  const page = useContext(pageContext)
  if (page === null)
    throw new Error('PageProvider not found')
  return page
}
