import type { Zone } from '../util/data/server'
import { ApiError, server } from '../util/data/server'
import { t } from '../util/intl/t'

type Result = { status: 'ok' }
  | { status: 'failed', error: ApiError | string }

export async function syncPageMetadata(pageID: string, zones: Array<Zone>, metaData: Record<string, any> | null): Promise<Result> {
  const sync = async () => {
    const payload = {
      ...metaData,
      zones,
    }
    return server.upsertPageMetadata(pageID, { metadata: JSON.stringify(payload) })
  }

  try {
    await sync()
    return { status: 'ok' }
  }
  catch (error) {
    await new Promise(resolve => setTimeout(resolve, 2000))
    try {
      await sync()
      return { status: 'ok' }
    }
    catch (error) {
      if (error instanceof ApiError)
        return { status: 'failed', error }
      return {
        status: 'failed',
        error: t('annot.sync.failed'),
        // Sync zone failed
      }
    }
  }
}
