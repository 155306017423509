import type { AIModelParams, AIModelParamsDetail, PieceSample, PieceSamples } from '../../generated/server'

export function isSampleParam(target: AIModelParams): target is PieceSamples {
  const test = target as PieceSamples
  return Array.isArray(test.samples)
}

export function parseSamplesFromParam(target: AIModelParamsDetail): SampleDetail[] {
  const { params } = target

  if (!isSampleParam(params))
    throw new Error(`AI Model Params is not Samples ${target.id}`)

  return params.samples.map((sample) => {
    const { group_id: group, image } = sample
    return { group, image }
  })
}

export function transformSamplesToParam(details: SampleDetail[]): PieceSamples {
  const samples: PieceSample[] = details.map(d => ({
    group_id: d.group,
    image: d.image,
  }))
  return { samples }
}

// One piece per AI param. See module's README.md for more details.
export type SampleDetail = {
  group: PieceSample['group_id']
  image: PieceSample['image']
}

/**
 * LMAO please see README.md for Sample ID section.
 */
export function getSampleDetailId(sample: SampleDetail): string {
  return sample.image
}
