import type { TldrawEditorBaseProps } from 'tldraw'
import { HandTool, SelectTool, ZoomTool } from 'tldraw'
import { createAnnotCutTool } from '../../annot/cut/tool'
import { createElectricalPieceTool } from '../../annot/piece/box/electrical-piece-tool'
import { createFirePieceTool } from '../../annot/piece/box/fire-piece-tool'
import { createMechanicalPieceTool } from '../../annot/piece/check/tool'
import { createPieceCircleTool } from '../../annot/piece/circle/tool'
import { PolygonZoneShapeTool } from '../../annot/polygon/zone/tool'
import { createSegmentExtensionTool } from '../../annot/segment/extension/tool'
import { createSegmentFlatDashedTool } from '../../annot/segment/flat/dashed-tool'
import { BaseLineTool } from '../../annot/segment/flat/line'
import { createSegmentFlatTool } from '../../annot/segment/flat/tool'
import { createSegmentVerticalPipeTool } from '../../annot/segment/vertical/pipe/tool'
import type { AttrRecord } from '../../attr/state/context'
import { ScaleTool } from '../../page/scale/tool'
import { PredictAreaTool } from '../../predict/area/tool'
import { PredictGroupSampleTool } from '../../predict/group-sample/tool'
import { PolygonAreaShapeTool } from '../../predict/polygon-area/tool'
import type { SetState } from '../../util/react/state'

export function createEditorTools(props: {
  setAttrs: SetState<AttrRecord>
}): TldrawEditorBaseProps['tools'] {
  const { setAttrs } = props

  return [
    HandTool,
    ZoomTool,
    SelectTool,
    //
    ScaleTool,
    PredictAreaTool,
    PredictGroupSampleTool,
    PolygonAreaShapeTool,
    PolygonZoneShapeTool,
    //
    BaseLineTool,
    //
    createMechanicalPieceTool({ setAttrs }),
    createElectricalPieceTool({ setAttrs }),
    createFirePieceTool({ setAttrs }),
    createPieceCircleTool({ setAttrs }),
    //
    createSegmentFlatTool({ setAttrs }),
    createSegmentFlatDashedTool({ setAttrs }),
    //
    createSegmentVerticalPipeTool({ setAttrs, direction: 'down' }),
    createSegmentVerticalPipeTool({ setAttrs, direction: 'up' }),
    createSegmentExtensionTool({ setAttrs }),
    //
    createAnnotCutTool({ setAttrs }),
  ]
}
