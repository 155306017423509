import type { TLDefaultColorStyle } from 'tldraw'
import { Box } from 'tldraw'
import { createSegmentFlatShape } from '../../annot/segment/flat/create'
import { createSegmentAttr } from '../../annot/segment/shape'
import { createSegmentVerticalPipeShape } from '../../annot/segment/vertical/pipe/create'
import { randomAnnotShapeColor } from '../../annot/shape/color'
import type { AttrEquipValue } from '../../attr/field/equip/value'
import { ATTR_TYPE_OPTIONS } from '../../attr/field/type/value'
import type { AIOutputBox, AIOutputFireProtectionPipeMetadata, AIOutputLabeledBox, AIOutputLine, Point } from '../../util/data/server'
import type { PredictFetchCropTransform } from '../fetch/crop'
import type { PredictFetchOutput } from '../fetch/type'
import { parsePredictEquip } from './equip'
import { parsePredictShape } from './shape'
import { parsePredictType } from './type'

/**
 * Convert a _single_ polyline to a list of lines.
 *
 * Careful: In practice, you will usually use this in a "flat map",
 * to convert a list of polylines to a list of lines.
 *
 * Don't "flat" the polylines to points,
 * as it will create lines that connect the last point of a polyline
 * to the first point of the next polyline, which is wrong.
 */
export function parsePredictPolyline(polyline: Array<Point>): Array<AIOutputLine> {
  const lines = polyline
    .map((p1, index, points): AIOutputLine | null => {
      const p2 = points.at(index + 1)
      return p2 ? { p1, p2 } : null
    })
    .filter((line): line is AIOutputLine => line !== null)
  return lines
}

/**
 * Common interface for legacy pipelines
 * e.g., AIOutputPipeline, AIOutputDRPipeline, AIOutputSanitaryPipeline
 *
 * New pipelinies should be transformed first to this interface
 * e.g., AIOutputFireProtectionPipeline
 */
type Pipeline = {
  diameters: AIOutputLabeledBox[]
  lines: Array<AIOutputLine & { temporaryId?: number, metadata?: AIOutputFireProtectionPipeMetadata }>
  type: string | null
  vertical_segments: AIOutputBox[] | null
  color?: TLDefaultColorStyle
}

export function parsePredictPipelines(props: {
  pipelines: Pipeline[]
  transform: PredictFetchCropTransform
  equipFallback: AttrEquipValue
  aiPredictionRemaining: number
}): PredictFetchOutput {
  const { transform, pipelines, equipFallback, aiPredictionRemaining } = props

  const output: PredictFetchOutput = { attrs: {}, shapes: [], aiPredictionRemaining }

  const typeOptions = ATTR_TYPE_OPTIONS[equipFallback] ?? []

  pipelines.forEach((pipeline) => {
    const group = crypto.randomUUID()
    const type = parsePredictType({ boxes: pipeline.diameters })

    const color = typeOptions.find(option => option.value === type)?.color
      ?? pipeline.color
      ?? randomAnnotShapeColor()

    // Pipeline attributes for all segments
    output.attrs[group] = {
      ...(createSegmentAttr()),
      equip:
        parsePredictEquip(pipeline.type ?? pipeline.diameters)
        ?? equipFallback,
      shape: parsePredictShape(pipeline.diameters),
      type,
    }

    // Flat segments
    // convert polylines to lines
    pipeline.lines.forEach((line) => {
      const visual = line.metadata?.visual_type === 'Bridge' ? 'dashed' : 'solid'

      const [start, end] = transform.line([line.p1, line.p2])
      const segment = createSegmentFlatShape({
        start,
        end,
        //
        id: null,
        color,
        group,
        dash: visual,
        //
        interactive: 'ByAI',
        zoneID: '',
        metadata: [{
          key: 'fire-pipe',
          value: line.metadata ? JSON.stringify({ ...line.metadata, temporaryId: line.temporaryId }) : '',
        }],
      })
      output.shapes.push(segment)
    });

    (pipeline.vertical_segments ?? []).forEach((box) => {
      const tlBox = new Box(box.x, box.y, box.width, box.height)
      const segment = createSegmentVerticalPipeShape({
        center: transform.vec(tlBox.center),
        direction: null, // AI cannot predict direction…
        mm: null, // …and mm yet
        //
        id: null,
        group,
        color,
        interactive: 'ByAI',
        zoneID: '',
      })
      output.shapes.push(segment)
    })
  })

  return output
}
