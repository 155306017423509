import type { CheckboxProps } from '@fluentui/react-components'
import { Checkbox, makeStyles, tokens } from '@fluentui/react-components'
import { type ReactElement, useContext } from 'react'
import { t } from '../../util/intl/t'
import { SampleSetValueContext, SampleValueContext } from '../context/value'
import { type SampleDetail, getSampleDetailId } from '../detail'
import { SampleOptionLabel } from './label'

const useStyles = makeStyles({
  checkbox: {
    'flex': '1 1 0px',
    ':hover': {
      background: tokens.colorSubtleBackgroundHover,
      borderRadius: tokens.borderRadiusMedium,
    },
  },
  checkboxIndicator: {
    alignSelf: 'center',
  },
  label: {
    width: '100%',
  },
})

export function SampleOptionCheck(props: {
  detail: SampleDetail
}): ReactElement {
  const { detail } = props

  const s = useStyles()
  const sampleContext = useContext(SampleValueContext)
  const setSampleContext = useContext(SampleSetValueContext)

  const id = getSampleDetailId(detail)

  const onChecked: CheckboxProps['onChange'] = (_event, data) => {
    const { checked } = data
    if (checked === 'mixed')
      throw new Error('mixed is not supported')

    setSampleContext((prev) => {
      const selected = new Set(prev.selected)
      selected[checked ? 'add' : 'delete'](id)
      const next = { ...prev, selected }
      return next
    })
  }

  return (
    <Checkbox
      checked={sampleContext.selected.has(id)}
      onChange={onChecked}
      aria-label={t('sample.option.check.use')}
      label={{
        className: s.label,
        children: <SampleOptionLabel detail={detail} />,
      }}
      indicator={{ className: s.checkboxIndicator }}
      className={s.checkbox}
    />
  )
}
