import { Divider, makeStyles, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import type { TLShapeId } from 'tldraw'
import { SamplePredictModel } from './model'
import { SamplePredictSave } from './save'

const useStyles = makeStyles({
  container: {
    // Consistent width to avoid layout shift
    width: '200px',
    // Not great to manage margin here,
    // but the usage (Predict Group Sample Select) is quite messy.
    marginTop: tokens.spacingVerticalM,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: tokens.spacingVerticalL,
  },
})

export function SamplePredictLibrary(props: {
  shapeId: TLShapeId
}): ReactElement {
  const { shapeId } = props

  const s = useStyles()

  return (
    <div className={s.container}>
      <Divider />
      <div className={s.body}>
        <SamplePredictSave id={shapeId} />
        <SamplePredictModel id={shapeId} />
      </div>
    </div>
  )
}
