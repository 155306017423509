import React from 'react'
import type {
  TLDefaultColorStyle,
  TLDefaultColorTheme,
  TLDefaultFillStyle,
} from 'tldraw'

export type ShapeFillProps = {
  d: string
  fill: TLDefaultFillStyle
  color: TLDefaultColorStyle
  theme: TLDefaultColorTheme
  className?: string
}

export const ShapeFill = React.memo(({ theme, d, color, fill }: ShapeFillProps) => {
  switch (fill) {
    case 'none': {
      return null
    }
    case 'solid': {
      return <path fill={theme[color].solid} d={d} />
    }
    case 'pattern':
    case 'semi': {
      return <path fill={theme[color].semi} d={d} />
    }
  }
})

ShapeFill.displayName = 'ShapeFill'
export default ShapeFill
