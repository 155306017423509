import { ArrowBidirectionalLeftRight20Regular, ArrowDown20Regular, ArrowUp20Regular, CheckmarkSquare20Regular, Cut20Regular, Line20Regular, LineDashes20Regular, RectangleLandscape20Regular, Square20Regular } from '@fluentui/react-icons'
import type { ReactElement } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { track, useEditor } from 'tldraw'
import { EditorToolButton } from '../../editor/tool/button'
import { ToolbarSpace } from '../../ui/toolbar/space'
import { t } from '../../util/intl/t'
import { ANNOT_CUT_TOOL_ID } from '../cut/tool'
import { ELECTRICAL_PIECE_TOOL_ID } from '../piece/box/electrical-piece-tool'
import { FIRE_PIECE_TOOL_ID } from '../piece/box/fire-piece-tool'
import { MECHANICAL_PIECE_TOOL_ID } from '../piece/check/tool'
import { SEGMENT_EXTENSION_TOOL_ID } from '../segment/extension/tool'
import { SEGMENT_FLAT_DASHED_TOOL_ID } from '../segment/flat/dashed-tool'
import { SEGMENT_FLAT_TOOL_ID } from '../segment/flat/tool'
import { SegmentGeoLength } from '../segment/geo/length'
import { SEGMENT_VERTICAL_PIPE_TOOL_IDS } from '../segment/vertical/pipe/tool'
import { AnnotToolbarDelete } from './delete'
import { AnnotToolbarDuplicate } from './duplicate'
import { AnnotToolbarExtract } from './extract'

export const AnnotToolbar = track((): ReactElement => {
  const editor = useEditor()

  useHotkeys('mod+a', () => editor.selectAll())

  return (
    <>
      <EditorToolButton id={MECHANICAL_PIECE_TOOL_ID} kbd="q" icon={<CheckmarkSquare20Regular />}>
        {t('annot.toolbar.mechanical-piece')}
      </EditorToolButton>
      <EditorToolButton id={FIRE_PIECE_TOOL_ID} icon={<Square20Regular />}>
        {t('annot.toolbar.fire-piece')}
      </EditorToolButton>
      <EditorToolButton id={ELECTRICAL_PIECE_TOOL_ID} icon={<RectangleLandscape20Regular />}>
        {t('annot.toolbar.electrical-piece')}
      </EditorToolButton>
      <EditorToolButton id={SEGMENT_FLAT_TOOL_ID} kbd="w" icon={<Line20Regular />}>
        {t('annot.toolbar.segment')}
      </EditorToolButton>
      <EditorToolButton id={SEGMENT_FLAT_DASHED_TOOL_ID} icon={<LineDashes20Regular />}>
        {/* {t('annot.toolbar.segment')} */}
        Disconnected segment
      </EditorToolButton>
      <EditorToolButton id={SEGMENT_VERTICAL_PIPE_TOOL_IDS.up} kbd="e" icon={<ArrowUp20Regular />}>
        {t('annot.toolbar.vertical-pipe-up')}
      </EditorToolButton>
      <EditorToolButton id={SEGMENT_VERTICAL_PIPE_TOOL_IDS.down} kbd="r" icon={<ArrowDown20Regular />}>
        {t('annot.toolbar.vertical-pipe-down')}
      </EditorToolButton>
      <EditorToolButton id={SEGMENT_EXTENSION_TOOL_ID} kbd="t" icon={<ArrowBidirectionalLeftRight20Regular />}>
        {t('annot.toolbar.extension')}
      </EditorToolButton>
      <SegmentGeoLength />
      <ToolbarSpace />
      <AnnotToolbarDuplicate />
      <EditorToolButton id={ANNOT_CUT_TOOL_ID} icon={<Cut20Regular />}>
        {t('annot.toolbar.cut')}
      </EditorToolButton>
      <AnnotToolbarExtract />
      <AnnotToolbarDelete />
    </>
  )
})
