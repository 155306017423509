import type { IndexKey, TLDefaultColorStyle, TLParentId, TLShape } from 'tldraw'
import { DefaultColorStyle, createShapeId } from 'tldraw'
import type { Zone } from '../../../../generated/server'
import type { EditorShapePartial } from '../../../editor/shape/base'
import { ANNOT_SHAPE_BASE } from '../../shape/base'
import { randomAnnotShapeColor } from '../../shape/color'
import type { PolygonShape } from '../shape'
import { isPolygonShape } from '../shape'

type Meta = {
  type: 'zone'
  zoneId: string
  zoneName?: string
  isClosed: boolean
  zoneShapeId?: string
}

export type ZoneShape = PolygonShape & { meta: Meta }

export type ZoneShapePartial = EditorShapePartial<
  ZoneShape,
  Meta
>
export function isZoneShape(
  shape: TLShape,
): shape is ZoneShape {
  if (!isPolygonShape(shape))
    return false
  const test = shape as ZoneShape
  return test.meta.type === 'zone'
}

export function isClosedZoneShape(
  shape: TLShape,
): shape is ZoneShape {
  if (!isPolygonShape(shape))
    return false
  const test = shape as ZoneShape
  return test.meta.type === 'zone' && test.meta.isClosed === true
}

export function fromServerZone(props: {
  server: Zone
}): ZoneShapePartial {
  const { server } = props
  const firstPoint = server.polygon.at(0)!

  const points: Record<string, {
    id: string
    index: IndexKey
    x: number
    y: number
  }> = server.polygon.reduce((acc, point, index) => {
    const key = `a${index + 1}` // Generates keys like "a1", "a2", etc.
    acc[key] = {
      id: key,
      index: key as IndexKey,
      x: point.x - firstPoint.x,
      y: point.y - firstPoint.y,
    }
    return acc
  }, {} as Record<string, {
    id: string
    index: IndexKey
    x: number
    y: number
  }>)

  return createZoneShape({
    points,
    color: DefaultColorStyle.validate('blue'),
    x: firstPoint.x ?? 0,
    y: firstPoint.y ?? 0,
    zoneId: server.id,
  })
}

export function createZoneShape(props: {
  points: Record<string, {
    id: string
    index: IndexKey
    x: number
    y: number
  }>
  color: TLDefaultColorStyle | null
  x: number
  y: number
  zoneId: string
}): ZoneShape {
  const { points, color, x, y, zoneId } = props

  const shape: ZoneShape = {
    ...(ANNOT_SHAPE_BASE),
    isLocked: false,
    id: createShapeId(),
    type: 'area',
    opacity: 1,
    x, // Add the x property
    y, // Add the y property
    rotation: 0, // Add the rotation property
    props: {
      points,
      size: 's',
      color: color ?? randomAnnotShapeColor(),
    },
    meta: {
      type: 'zone',
      zoneId: zoneId ?? '',
      isClosed: true,
    },
    typeName: 'shape',
    parentId: 'page:page' as TLParentId,
    index: 'a1' as IndexKey,
  }
  return shape
}
