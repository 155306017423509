import type { ReactElement } from 'react'
import { track, useEditor } from 'tldraw'
import { HelpLineHandleStatic } from '../../../help/actor/line-handle-static.tsx'
import { HelpLineScale } from '../../../help/actor/line-scale.tsx'
import { HelpPointTranslate } from '../../../help/actor/point-translate.tsx'
import { HelpCanvas } from '../../../help/card/canvas.tsx'
import { HelpCard } from '../../../help/card/card.tsx'
import { HelpText } from '../../../help/card/text.tsx'
import { t } from '../../../util/intl/t.ts'
import { SEGMENT_FLAT_TOOL_ID } from './tool.ts'

export const SegmentFlatHelp = track((): ReactElement | null => {
  const editor = useEditor()

  if (editor.getCurrentToolId().includes(SEGMENT_FLAT_TOOL_ID))
    return null

  return (
    <HelpCard>
      <HelpText>
        {t('annot.segment.help-1')}
      </HelpText>

      <HelpText>
        {t('annot.segment.help-2')}
      </HelpText>

      <HelpCanvas>
        <HelpLineHandleStatic />
        <HelpLineScale />
        <HelpPointTranslate target="line" />
      </HelpCanvas>
      <HelpText>
        {t('annot.segment.help-3')}
      </HelpText>
      <HelpText>
        {t('annot.segment.help-4')}
      </HelpText>
    </HelpCard>
  )
})
