import { Select } from '@fluentui/react-components'
import { useQuery } from '@tanstack/react-query'
import type { ReactElement } from 'react'
import { locale } from '../../util/intl/locale/type'
import { sampleQueryModelList } from '../query'

export function SampleModelSelect(props: {
  value: string
  setValue: (value: string) => void
}): ReactElement {
  const { value, setValue } = props

  const query = useQuery({
    ...sampleQueryModelList(),
    // Careful, this is "locale", not "localed"
    select: data => data.filter(model => model.locale === locale),
  })

  // This is important.
  // The default value is usually an empty string instead of null.
  // Without this, Select will show the first option,
  // even when the value is wrong (""),
  // leading the user to mistakenly think that a model is selected.
  const placeholder = (
    <option value="">
      Select a model
    </option>
  )

  return (
    <Select
      value={value}
      onChange={(_, data) => setValue(data.value)}
      appearance="filled-darker"
    >
      {placeholder}
      {query.data?.map(model => (
        <option key={model.ai_model_id} value={model.ai_model_id}>
          {model.localed}
        </option>
      ))}
    </Select>
  )
}
