import { queryOptions } from '@tanstack/react-query'
import { server } from '../util/data/server'

export function sampleQueryModelList() {
  return queryOptions({
    queryKey: ['sample', 'model'],
    queryFn: () => server.listLocaledAiModels(),
  })
}

export function sampleQueryList(constructionID: string) {
  return queryOptions({
    queryKey: ['sample', 'list'],
    queryFn: () => server.listConstructionAiModelParams(constructionID),
  })
}
